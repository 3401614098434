export const navLeftLinks = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Disclaimer",
    link: "/disclaimer",
  },
];
